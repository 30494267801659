export enum NameEntityType {
    KEY_SKILL = 'KEY_SKILL',
    SECTOR = 'SECTOR',
    LANGUAGE = 'LANGUAGE',
    TRAINING = 'TRAINING',
    PROJECT_ROLE = 'PROJECT_ROLE',
    COMPANY = 'COMPANY',
    CAREER = 'CAREER',
    QUALIFICATION = 'QUALIFICATION',
    EDUCATION = 'EDUCATION'
}